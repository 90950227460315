<template>
  <div class="page-login-desktop">
    <Table
        label="لیست محلات"
        icon="mdi-format-list-bulleted"
        :table="table">
      <template slot="header">
        <div class="d-flex align-center justify-end">
          <v-row>
            <v-col :cols="6">
              <v-select
                  outlined
                  label="منطقه"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :items="areaItems"
                  v-model="newLocation.areaId"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                  outlined
                  label="نام محله"
                  hide-details
                  v-model="newLocation.name"
              />
            </v-col>
          </v-row>
          <v-btn
              :disabled="!(newLocation.name && newLocation.areaId)"
              :loading="newLocation.loading"
              @click="submit"
              elevation="0"
              large
              color="main"
              class="white--text  mr-2"
          >
            افزودن
          </v-btn>
        </div>
      </template>
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.name }}</td>
        <td class="text-center">
          <EditButton
              @click="item.actions.edit"
          />
        </td>
      </tr>
    </Table>

    <v-dialog
        width="500"
        v-model="location.modal">
      <v-card>
        <v-card-title class="text-body-1 font-weight-bold">
          <v-icon class="ml-2">
            mdi-pencil
          </v-icon>
          ویرایش محله ها
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-select
                  outlined
                  label="منطقه"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :items="areaItems"
                  v-model="location.areaId"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                  outlined
                  label="نام محله"
                  hide-details
                  v-model="location.name"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              text
              @click="location.modal=false"
              large color="main">
            لغو
          </v-btn>
          <v-btn
              :loading="location.loading"
              class="white--text"
              @click="update"
              large color="main">
            ذخیره
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table"
import EditButton from "@/components/EditButton"
import Pagination from "@/components/Pagination"
import {AppConfig} from "@Newfiling/Services";
import {update, store} from "@Newfiling/module-location/src/api";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    EditButton,
  },

  data() {
    return {
      table: {
        headers: [
          '#',
          'نام',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      areaItems: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
      ],

      location: {
        id: null,
        modal: false,
        loading: false,
        name: null,
        areaId: null,
      },

      newLocation: {
        loading: false,
        name: null,
        areaId: null,
      },
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست محله ها',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    async fetchItems() {
      this.table.loading = true;
      localStorage.removeItem('app-config')
      const appConfig = (await AppConfig) || {};
      this.table.items = appConfig.neighborhoods.map(item => {
        return {
          name: item.NeighborhoodName,
          actions: {
            edit: () => {
              this.location = {
                ...this.location,
                modal: true,
                edit: true,
                name: item.NeighborhoodName,
                areaId: item.Areaid,
                id: item.Id,
              }
            }
          }
        }
      });
      this.table.loading = false;
    },

    async submit() {
      this.newLocation.loading = true;
      try {
        await store({
          NeighborhoodName: this.newLocation.name,
          Areaid: this.newLocation.areaId
        });
        this.$toast.success('محله با موفقیت اضافه شد.');
        this.newLocation = {
          ...this.newLocation,
          loading: false,
          name: null,
          areaId: null,
        }
        await this.fetchItems()
      } catch (e) {
        console.log(e)
      }
      this.newLocation.loading = false;
    },

    async update() {
      this.location.loading = true;
      try {
        await update({
          id: this.location.id,
          NeighborhoodName: this.location.name,
          Areaid: this.location.areaId,
        })
        this.location = {
          ...this.location,
          id: null,
          modal: false,
          loading: false,
          name: null,
          areaId: null,
        }
        this.$toast.success('منظقه با موفقیت ویرایش شد.');
        await this.fetchItems()
      } catch (e) {
        console.log(e)
      }
      this.location.loading = false;
    }
  }

}
</script>
